.balance-height-for-form {
  height: calc(100% - 0px);
}

.balance-height-for-form-edit {
  height: calc(100% - 117px);
}

.border-left-quaternary {
  border-left: 2px solid #c2c2c2;
}

.border-bottom-quaternary {
  border-bottom: 2px solid #c2c2c2;
}

.remaining-width-for-employee-card {
  width: calc(100% - 72px);
}

.remaining-height-for-search-results {
  height: calc(100% - 81px);
}

.remaining-height-for-search-sensors {
  height: calc(100% - 34.55px);
}

.remaining-height-for-selected-locations {
  height: calc(100% - 65px);
}

.remaining-height-for-procedures {
  height: calc(100% - 56px);
}

.remaining-height-for-locations {
  height: calc(100% - 298px);
}

.remaining-height-for-procedure {
  height: calc(100% - 135px);
}

.selected-background-color {
  background-color: #f2f2f2;
}

.width-200px {
  width: 200px;
}

.one-line-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.justify-content-end {
  justify-content: end;
}

.width-24px {
  width: 24px;
}

.height-24px {
  height: 24px;
}
