.add-blink-effect {
  transition: background-color 1.5s;
}

.man-power-color {
  background-color: #ff7b31;
}

@media screen and (max-width: 1500px) {
  .font-size-smaller {
    font-size: 8px;
  }

  .font-size-small {
    font-size: 10px;
  }

  .font-size-medium {
    font-size: 12px;
  }

  .font-size-default {
    font-size: 14px;
  }

  .font-size-large {
    font-size: 16px;
  }

  .font-size-larger {
    font-size: 18px;
  }
}

@media screen and (max-height: 800px) {
  .font-size-smaller {
    font-size: 8px;
  }

  .font-size-small {
    font-size: 10px;
  }

  .font-size-medium {
    font-size: 12px;
  }

  .font-size-default {
    font-size: 14px;
  }

  .font-size-large {
    font-size: 16px;
  }

  .font-size-larger {
    font-size: 18px;
  }
}

.bottom-navigation-background-color-dark {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #313338 100%);
}
.bottom-navigation-background-color-light {
  background: linear-gradient(
    180deg,
    rgba(194, 194, 194, 0),
    rgba(194, 194, 194, 1)
  );
}

.background-gradient-light {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
}

.background-gradient-dark {
  background: linear-gradient(180deg, rgba(106, 106, 106, 0) 0%, #8f8f8f 100%);
}

.navigation-border-dark,
.navigation-border-light {
  position: relative;
}

.navigation-border-dark:before,
.navigation-border-dark:after,
.navigation-border-light:before,
.navigation-border-light:after {
  content: "";
  display: block;
  position: absolute;
  background-color: transparent;
  pointer-events: none;
}
/* 
.navigation-border:before {
  top: 0;
  left:0;
  right: 60%;
bottom: 80%;
  border-top: 4px solid red;
  border-left: 4px solid red;
} */

.navigation-border-dark:after {
  top: 70%;
  left: 0%;
  right: 0;
  bottom: 0;
  border-bottom: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
}

.navigation-border-light:after {
  top: 70%;
  left: 0%;
  right: 0;
  bottom: 0;
  border-bottom: 1px solid #404040;
  border-right: 1px solid #404040;
}

.border-bottom-1px-e5e5e5 {
  border-bottom: 1px solid #e5e5e5;
}

.border-bottom-1px-404040 {
  border-bottom: 1px solid #404040;
}

.width-224-px {
  width: 224px;
}

.qr-loader {
  margin-top: 0.2em;
  margin-bottom: 0.2em;
  text-indent: -9999em;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #c2c2c2;
  background: -moz-linear-gradient(left, #c2c2c2 10%, rgba(0, 200, 128, 0) 42%);
  background: -webkit-linear-gradient(
    left,
    #c2c2c2 10%,
    rgba(0, 200, 128, 0) 42%
  );
  background: -o-linear-gradient(left, #c2c2c2 10%, rgba(0, 200, 128, 0) 42%);
  background: -ms-linear-gradient(left, #c2c2c2 10%, rgba(0, 200, 128, 0) 42%);
  background: linear-gradient(to right, #c2c2c2 10%, rgba(0, 200, 128, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.qr-loader:before {
  width: 50%;
  height: 50%;
  background: #c2c2c2;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
}

.qr-loader:after {
  background: #020d23;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.info-card-box-shadow {
  -webkit-box-shadow: 1px 1px 12px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 1px 12px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 12px 0px rgba(0, 0, 0, 0.2);
}
.poi-info-window .address {
  background-color: #020d23 !important;
}

.poi-info-window div,
.poi-info-window a {
  background-color: #020d23 !important;
  color: white;
}

.gm-style-iw-chr {
  display: none !important;
}

.gm-style .gm-style-iw-c {
  background-color: #020d23 !important;
  min-width: 350px !important;
  border: solid 1px grey !important;
  padding: 0px !important;
  border-radius: 0px !important;
}

.gm-style-iw-d {
  padding: 16px !important;
  overflow: hidden !important;
}

.gm-style .gm-style-iw-tc::after {
  background-color: #020d23 !important;
}

@media screen and (max-height: 720px) {
  .responsive-graph-section-height {
    height: 200px;
  }
}

@media screen and (min-height: 721px) and (max-height: 1080px) {
  .responsive-graph-section-height {
    height: 250px;
  }
}

@media screen and (min-height: 1081px) {
  .responsive-graph-section-height {
    height: 300px;
  }
}
