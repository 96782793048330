.selected-background-color-control-button {
  background-color: #002f44;
}

.corner {
  width: 4px;
  height: 4px;
  background-color: white;
  position: absolute;
}

.top-left {
  top: -1px;
  left: -1px;
}

.top-right {
  top: -1px;
  right: -1px;
}

.bottom-left {
  bottom: -1px;
  left: -1px;
}

.bottom-right {
  bottom: -1px;
  right: -1px;
}
