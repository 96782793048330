.width-1200px {
    width: 1200px
}

.max-width-1200px {
    max-width: 1200px
}

.unsupported-device-fallback-image {
    height: 250px;
}

.unsupported-device-primary-content-web {
    font-size: 50px;
}

.unsupported-device-primary-content-clinics {
    font-size: 50px;
}

.height-25px {
    height: 25px
}

.height-100px {
    height: 100px
}

.width-100px {
    width: 100px;
}

.font-size-10px {
    font-size: 10px
}

.border-bottom-1px-e5e5e5 {
    border-bottom: 1px solid #e5e5e5;
}

.unsupported-device-footer {
    display: flex;
    justify-content: center;
    border-top: 1px solid #e5e5e5;
}

.unsupported-device-primary-content-phone {
    display: none;
}

.unsupported-device-secondary-content-phone {
    display: none;
}


@media only screen and (max-width: 900px) {

    .unsupported-device-qr-code {
        margin-top: 0%;
    }


    .unsupported-device-body {
        flex-direction: column;
    }

    .unsupported-device-content-area {
        padding-top: 50px;
        padding-left: 0%;
        padding-right: 0%;
    }

    .unsupported-device-primary-content-web {
        font-size: 35px;
    }

    .unsupported-device-primary-content-clinics {
        font-size: 35px;
    }

    .unsupported-device-secondary-content-web {
        font-size: 18px;
    }

    .unsupported-device-secondary-content-clinics {
        font-size: 18px;
    }

    .unsupported-device-fallback-image {
        height: 150px;
    }
}

@media only screen and (max-width: 575px) {

    .unsupported-device-footer {
        display: none;
    }

    .unsupported-device-qr-code {
        display: none;
    }

    .unsupported-device-primary-content-web {
        display: none;
    }

    .unsupported-device-primary-content-phone {
        font-size: 20px;
        display: block;
    }

    .unsupported-device-primary-content-clinics {
        font-size: 20px;
    }

    .unsupported-device-secondary-content-web {
        display: none;
    }

    .unsupported-device-secondary-content-phone {
        font-size: 12px;
        display: block;
    }

    .unsupported-device-secondary-content-clinics {
        font-size: 12px;
    }

    .app-download-button {
        margin-top: 0%;
    }

}