.border-left-dashed-line-white {
  border: none;
  border-left: 1px dashed #fff;
}

.border-left-dashed-line-dark {
  border: none;
  border-left: 1px dashed #313338;
}

.height-180px {
  height: 180px;
}

.height-10px {
  height: 10px;
}

.width-10px {
  width: 10px;
}

.parent-width-sub-22px {
  width: calc(100% - 22px);
}

.white-space-no-normal {
  white-space: normal;
}

.parent-width-sub-156px {
  width: calc(100% - 156px);
}

.width-156-px {
  width: 156px;
}

.min-width-170px {
  min-width: 170px;
}
