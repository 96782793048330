.custom-scrollbar {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.scroll-content {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  /* Hide the default scrollbar */
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.scroll-content::-webkit-scrollbar {
  display: none;
}

.scroll-thumb {
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.arrow {
  font-size: 14px;
  color: white;
  transform: rotate(90deg);
}

.arrow-disabled {
  font-size: 14px;
  color: white;
  opacity: 50%;
  transform: rotate(90deg);
}

/* ruler */

.ruler {
  position: relative;
  width: 45px;
  border-left: 1px solid white;
}

.ruler-disabled {
  position: relative;
  width: 45px;
  border-left: 1px solid white;
  opacity: 50%;
}

.ruler-line {
  position: absolute;
  width: 100%;
}

.short-line {
  height: 1px;
  width: 8px;
  background-color: white;
}

.short-line-disabled {
  height: 1px;
  width: 8px;
  background-color: white;
  opacity: 50%;
}

.long-line {
  height: 1px;
  width: 16px;
  background-color: white;
}

.long-line-disabled {
  height: 1px;
  width: 16px;
  background-color: white;
  opacity: 50%;
}
