.min-width-44px {
  min-width: 44px;
}

.min-width-55px {
  min-width: 55px;
}

.min-width-65px {
  min-width: 65px;
}

.min-width-fit {
  min-width: fit-content;
}

.min-height-fit {
  min-height: fit-content;
}

.max-height-65-percentage {
  max-height: 65%;
}

.max-height-35-percentage {
  max-height: 35%;
}

.max-width-94px {
  max-width: 94px;
}

.height-65-percentage {
  height: 65%;
}

.height-35-percentage {
  height: 35%;
}

.max-width-4-percentage {
  max-width: 4%;
}

.width-15-percentage {
  width: 15%;
}

.flex-justify-content-left {
  justify-content: left;
}

.background-color-f2f2f2 {
  background-color: #f2f2f2;
}

.background-color-yellow {
  background: orange;
}

.transition-effect {
  transition: background-color 0.2s ease-in-out;
}

@keyframes fadeMe {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.border-left-quaternary-2px {
  border-left: 2px solid #c2c2c2;
}

.border-bottom-quaternary-2px {
  border-bottom: 2px solid #c2c2c2;
}

.min-width-100px {
  min-width: 100px;
}

.min-width-200px {
  min-width: 200px;
}

.white-space-normal {
  white-space: normal;
}

.adaptive-height-for-graph {
  height: 65%;
}

.adaptive-height-for-pie {
  height: 35%;
}

.border-top-tertiary-dark {
  border-top: 1px solid var(--tertiaryDarkColor);
}

.border-right-tertiary-dark {
  border-right: 1px solid var(--tertiaryDarkColor);
}

/* @media screen and (max-height: 750px) {
  .adaptive-height-for-graph {
    height: fit-content;
  }

  .adaptive-height-for-pie {
    height: fit-content;
  }
} */

.parent-height-sub-42px {
  height: calc(100% - 42px);
}
