.padding-32px {
  padding: 32px;
}

.filter-background-image {
  padding-bottom: 24px;
  background-image: url("../../Assets/FilterTop.svg"),
    url("../../Assets/FilterBottom.svg"), url("../../Assets/FilterLeft.svg"),
    url("../../Assets/FilterRight.svg"), url("../../Assets/FilterCenter.svg");
  background-position: top, bottom, left, right, center;
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
  background-size: contain, contain, contain, contain, contain;
}

.border-left-2px-quaternary {
  border-left: 2px solid #c2c2c2;
}

.border-bottom-2px-quaternary {
  border-bottom: 2px solid #c2c2c2;
}

.border-bottom-2px-secondary {
  border-bottom: 2px solid #404040;
}

.border-bottom-1px-secondary {
  border-bottom: 1px solid #404040;
}

.border-bottom-2px-white {
  border-bottom: 2px solid white;
}

.font-size-32px {
  font-size: 24px;
}

.width-400px {
  width: 400px;
}

.background-selected-card-gradient-e5e5e5-dark-left-to-right {
  background: linear-gradient(
    90deg,
    rgba(229, 229, 229, 0.125) 0%,
    rgba(229, 229, 229, 0) 100%
  );
}

.background-selected-card-gradient-e5e5e5-light-left-to-right {
  background: linear-gradient(
    90deg,
    rgba(229, 229, 229, 1),
    rgba(229, 229, 229, 0)
  );
}

.background-selected-card-gradient-e5e5e5-dark-right-to-left {
  background: linear-gradient(
    270deg,
    rgba(229, 229, 229, 0.125) 0%,
    rgba(229, 229, 229, 0) 100%
  );
}

#input-box-selection input[type="text"] {
  padding: 8px;
}
