.border-radius-top-right-default {
  border-radius: 0px 8px 0 0;
}

.border-bottom-tertiary-dark {
  border-bottom: 1px solid var(--tertiaryDarkColor);
}

.border-left-tertiary-dark {
  border-left: 1px solid var(--tertiaryDarkColor);
}

.background-color-black {
  background-color: #000;
}

.width-1100px {
  width: 1100px;
}

.height-480px {
  height: 480px;
}

.animated-progress {
  transition: background-color 0.5s ease-out;
}
