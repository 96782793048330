.height-500px {
  height: 156px !important;
}
.min-width-356px {
  min-width: 356px !important;
}

.height-width {
  width: calc(100% - 0px) !important;
  height: auto !important;
}

/* @media screen and (max-width: 1500px) {
  .height-500px {
    height: 156px !important;
  }
} */

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 91%;
  background: rgba(2, 13, 35, 0.24);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.2px);
  -webkit-backdrop-filter: blur(7.2px);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 10; /* Make sure it's on top of the chart */
}
