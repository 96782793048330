
.dot-pulse {
  z-index: 1;
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffff;
  color: #ffff;
  box-shadow: 9999px 0 0 -5px #ffff;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dot-pulse::before,
.dot-pulse::after {
  z-index: 1;
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffff;
  color: #ffff;
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px #ffff;
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px #ffff;
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px #ffff;
  }

  30% {
    box-shadow: 9984px 0 0 2px #ffff;
  }

  60%,
  100% {
    box-shadow: 9984px 0 0 -5px #ffff;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px #ffff;
  }

  30% {
    box-shadow: 9999px 0 0 2px #ffff;
  }

  60%,
  100% {
    box-shadow: 9999px 0 0 -5px #ffff;
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px #ffff;
  }

  30% {
    box-shadow: 10014px 0 0 2px #ffff;
  }

  60%,
  100% {
    box-shadow: 10014px 0 0 -5px #ffff;
  }
}

.dot-pulse-dark {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #cfcfcf;
  color: #cfcfcf;
  box-shadow: 9999px 0 0 -5px #cfcfcf;
  animation: dotPulseDark 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dot-pulse-dark::before,
.dot-pulse-dark::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #cfcfcf;
  color: #cfcfcf;
}

.dot-pulse-dark::before {
  box-shadow: 9984px 0 0 -5px #cfcfcf;
  animation: dotPulseDarkBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse-dark::after {
  box-shadow: 10014px 0 0 -5px #cfcfcf;
  animation: dotPulseDarkAfter 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dotPulseDarkBefore {
  0% {
    box-shadow: 9984px 0 0 -5px #cfcfcf;
  }

  30% {
    box-shadow: 9984px 0 0 2px #cfcfcf;
  }

  60%,
  100% {
    box-shadow: 9984px 0 0 -5px #cfcfcf;
  }
}

@keyframes dotPulseDark {
  0% {
    box-shadow: 9999px 0 0 -5px #cfcfcf;
  }

  30% {
    box-shadow: 9999px 0 0 2px #cfcfcf;
  }

  60%,
  100% {
    box-shadow: 9999px 0 0 -5px #cfcfcf;
  }
}

@keyframes dotPulseDarkAfter {
  0% {
    box-shadow: 10014px 0 0 -5px #cfcfcf;
  }

  30% {
    box-shadow: 10014px 0 0 2px #cfcfcf;
  }

  60%,
  100% {
    box-shadow: 10014px 0 0 -5px #cfcfcf;
  }
}

@keyframes dotPulseHoverBefore {
  0% {
    box-shadow: 9984px 0 0 -5px #404040;
  }

  30% {
    box-shadow: 9984px 0 0 2px #404040;
  }

  60%,
  100% {
    box-shadow: 9984px 0 0 -5px #404040;
  }
}

@keyframes dotPulseHover {
  0% {
    box-shadow: 9999px 0 0 -5px #404040;
  }

  30% {
    box-shadow: 9999px 0 0 2px #404040;
  }

  60%,
  100% {
    box-shadow: 9999px 0 0 -5px #404040;
  }
}

@keyframes dotPulseHoverAfter {
  0% {
    box-shadow: 10014px 0 0 -5px #404040;
  }

  30% {
    box-shadow: 10014px 0 0 2px #404040;
  }

  60%,
  100% {
    box-shadow: 10014px 0 0 -5px #404040;
  }
}

@media screen and (min-width: 576px) {
  .top-right {
    top: 116px;
  }

  .bottom-right {
    right: 36px;
    bottom: 36px;
  }

  .dot-pulse {
    z-index: 1;
    position: relative;
    left: -9999px;
    width: 15px;
    height: 15px;
    border-radius: 8px;
    animation-delay: 0.25s;
  }

  .dot-pulse::before,
  .dot-pulse::after {
    z-index: 1;
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 15px;
    height: 15px;
    border-radius: 8px;
  }
}

.triangle-bottom-left-corner {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid white;
  transform: rotate(-45deg);
}

.triangle-bottom-right-corner {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid white;
  transform: rotate(135deg);
}



/* Primary button---------------------------------- */

.primary-button {
  position: relative;
  display: block;
  overflow: hidden;
  height: 44px;
  border: 1px solid currentColor;
  /* color: #ffffff; */
  background-color: transparent;
  font-weight: 500;
  transition: all 0.8s ease-in-out;
  /* transition: box-shadow 1s ease-in-out; */
}

.primary-button:before,
.primary-button:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  transition: 0.5s ease-in-out;
  /* z-index: -1; */
}

.primary-button:before {
  border-right: 80px solid transparent;
  border-top: 150px solid #ffffff;
  transform: translateX(-100%);
  rotate: -72deg;
  top: -465%;
}

.primary-button:after {
  left: -72px;
  border-left: 50px solid transparent;
  border-top: 200px solid #ffffff;
  transform: translateX(100%);
  rotate: -33deg;
}

.primary-button:hover {
  color: #000000 !important;
}

.primary-button:hover .dot-pulse{
  box-shadow: 9999px 0 0 -5px #404040;
  animation: dotPulseHover 1.5s infinite linear;
  animation-delay: 0.25s;
}

.primary-button:hover .dot-pulse::after{
  box-shadow: 9999px 0 0 -5px #404040;
  animation: dotPulseHoverAfter 1.5s infinite linear;
  animation-delay: 0.5s;
}

.primary-button:hover .dot-pulse::before{
  box-shadow: 9999px 0 0 -5px #404040;
  animation: dotPulseHoverBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.primary-button:hover:before {
  transform: translateX(-49%);
}

.primary-button:hover:after {
  transform: translateX(49%);
}



/* Secondary button ---------------------------------- */

.secondary-button {
  position: relative;
  display: block;
  overflow: hidden;
  height: 44px;
  border: 1px solid currentColor;
  /* color: #ffffff; */
  background-color: transparent;
  font-weight: 500;
}

.secondary-button:before,
.secondary-button:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  transition: 0.5s ease-in-out;
  /* z-index: -1; */
}

.secondary-button:before {
  border-right: 80px solid transparent;
  border-top: 150px solid #A9A9A9;
  transform: translateX(-100%);
  rotate: -72deg;
  top: -465%;
}

.secondary-button:after {
  left: -72px;
  border-left: 50px solid transparent;
  border-top: 200px solid #A9A9A9;
  transform: translateX(100%);
  rotate: -33deg;
}

.secondary-button:hover {
  color: #000000 !important;
  transition: 0.8s ease-in-out;
}

.secondary-button:hover:before {
  transform: translateX(-49%);
}

.secondary-button:hover:after {
  transform: translateX(49%);
}