.table-header-border {
  border: 1px solid #ffffff1f;
}

.table-background-image {
  margin-top: 24px;
  background-image: url("../../Assets/TableLeft.svg"),
    url("../../Assets/TableRight.svg");
  background-position: left bottom, right bottom;
  background-repeat: no-repeat, no-repeat;
  background-size: auto 105%, auto 105% !important;
}

.table-body-border {
  border-top: 1px solid #ffffff1f;
  border-left: 1px solid #ffffff1f;
}

.table-body-border-right {
  border-right: 1px solid #ffffff1f;
}

.table-body-border-bottom {
  border-bottom: 1px solid #ffffff1f;
}

.odd-cell-color {
  background-color: #14234280;
}

.table-corner {
  width: 3px;
  height: 3px;
  background-color: white;
  position: absolute;
}

.table-top-left {
  top: -1px;
  left: -1px;
}

.table-top-right {
  top: -1px;
  right: -2px;
}

.table-bottom-left {
  bottom: -1px;
  left: -1px;
}

.table-bottom-right {
  bottom: -1px;
  right: -1px;
}

.background-color-offline {
  background-color: #7F00FF;
}