/* Tooltip text */
.top {
  position: absolute;
  z-index: 1;
  right: 0%;
  bottom: 28px;
}

.bottom {
  position: absolute;
  z-index: 1;
  right: 0%;
  top: 28px;
}

.background-color-e2e2e2-dark {
  background-color: rgb(229, 229, 229, 1);
}
.background-color-e2e2e2-light {
  background-color: rgb(229, 229, 229, 1);
}
